import { makeObservable, action, computed, observable, reaction } from 'mobx';

import { App } from '..';

export interface IGiveawaysData {
	list: IGiveawaysDataItem[];
}

export interface IGiveawaysDataItem {
	id?: string;
	itemId: string;
	campaignId?: string;
	resourceId?: string;
	type: 'unknown' | 'digital' | 'physical' | 'promocode';
	dateStart: Date;
	title: string;
	media: string;
	code?: string; // For digital items
}

export interface IGiftCRMData {
	id: string;
	oid: string;
	title: string;
	tag: string;
	type: 'physical' | 'digital' | 'promocode';
	image: {
		id: string;
		oid: string;
		ar: boolean;
		filename: string;
		mimeType: string;
		filesize: number;
		width: number;
		height: number;
		focalX: number;
		focalY: number;
		createdAt: string;
		updatedAt: string;
		url: string;
	};
	createdAt: string;
	updatedAt: string;
}

export class GiveawaysModel {
	@observable
	protected $isInitialized: boolean = false;

	@observable
	protected $crmCache: Map<string, IGiftCRMData> = new Map();

	@observable
	protected $data: IGiveawaysData = {
		list: [],
	};

	@computed
	public get isInitialized(): boolean {
		return this.$isInitialized && this.app.models.leaderboard.isInitialized;
	}

	@computed
	public get items(): IGiveawaysDataItem[] {
		return this.$data.list
			.filter((item) => item.resourceId === this.app.models.leaderboard.currentLeaderboardId.resourceId)
			.map((item) => {
				const cached = this.$crmCache.get(item.itemId);

				if (!cached) {
					return item;
				}

				return {
					...item,
					id: cached.id,
					type: cached.type ?? item.type,
					title: cached.title ?? item.title,
					media: cached.image?.url ?? item.media,
				};
			})
			.sort((a, b) => b.dateStart.getTime() - a.dateStart.getTime());
	}

	public constructor(public readonly app: App) {
		makeObservable(this);
	}

	@action
	public initialize(data: IGiveawaysData) {
		this.$data = data;

		reaction(
			() => Array.from(new Set(this.items.map((item) => item.itemId)).values()),
			(itemIds) => {
				const requests = itemIds.reduce((result, itemId) => {
					if (this.$crmCache.has(itemId)) {
						return result;
					}

					return [
						...result,
						this.app.api.crm.request<IGiftCRMData>(`/gifts/get-by-tag/${itemId}/${ORGANIZATION_ID}`, {
							method: 'get',
						}),
					];
				}, [] as Promise<IGiftCRMData>[]);

				if (!requests.length) {
					return;
				}

				Promise.all(requests).then(
					action((responses) => {
						for (const response of responses) {
							this.$crmCache.set(response.tag, response);
						}
					})
				);
			}
		);

		this.$isInitialized = true;
	}
}
