import type { TLexicalContent } from 'src/components';
import type { TIntlLocale } from '../models/intl';

export type TWhereAmIResponseIdentity = 'twitch' | 'google' | 'telegram' | 'steam' | 'winline' | 'paripass';

export interface IWhereAmIResponse {
	EnvPlace: {
		Domain: string;
		Slug: string;
	};

	Leaderboards: {
		List: IWhereAmIResponseLeaderboard[];
		Total: number;
	};

	IdentityProviders: {
		Identities: {
			twitch: string;
			google?: string;
			telegram?: string;
			steam?: string;
			winline?: string;
			paripass?: string;
		};
	};

	IdentityProvidersConfigs: {
		Identities: {
			[key in TWhereAmIResponseIdentity]:
				| {
						Link: string;
						Script?: null;
						Data?: {};
				  }
				| {
						Link?: null;
						Script: string;
						Data: Record<string, string>;
				  };
		};
	};

	Profile: IWhereAmIResponseProfile | null;
}

export interface IWhereAmIResponseProfile {
	Username: string;
	Email: string;
	ProfilePic: string;
	ConnectedAccounts: {
		twitch?: string;
		google?: string;
		telegram?: string;
		steam?: string;
		winline?: string;
		paripass?: string;
	};
	Meta: {
		contacts_name_second: string;
		contacts_name_first: string;
		contacts_name_fathers: string;
		contacts_address_country: string;
		contacts_address_index: string;
		contacts_address_city: string;
		contacts_address_value: string;
		contacts_phone: string;
		contacts_email: string;
		contacts_telegram: string;

		[key: string]: string;
	};
	Stored: {
		ID: {
			OrganizationID: string;
			ProfileID: {
				ID: string;
				Platform: string;
			};
		};
		FromMatches: {
			[key: string]: {
				Day: {
					IntegrationName: string;
					IntegrationType: string;
					MechanicsID: string;
					Data: {
						CampaignID: string;
						IntegrationID: string;
						At: string;
						Resources: IWhereAmIResponseResources;
						Tags: IWhereAmIResponseTag[];
					};
				}[];
			};
		};
		FromSources: {
			[key: string]: {
				CampaignID: string;
				IntegrationID: string;
				At: string | null;
				Resources: IWhereAmIResponseResources;
				Tags: IWhereAmIResponseTag[];
			};
		};
		Merged: {
			Resources: IWhereAmIResponseResources;
			Tags: IWhereAmIResponseTag[];
		};
	};
}

export interface IWhereAmIResponseResources {
	[key: string]: {
		Amount: number;
		Spent: number;
	};
}

export interface IWhereAmIResponseTag {
	Tag: string;
	Meta: {
		[key: string]: string;
	};
	ActiveSince: string | null;
	ActiveUntil: string | null;
}

export interface IWhereAmIResponseLeaderboard {
	ID: {
		OrganizationID: string;
		ResourceID: string;
	};
	CampaignsIDs: string[];
	LastReRank: string;
	RequestedReRank: string;
	Tiers: IWhereAmIResponseLeaderboardTier[];
	MerchandiseGiveaways: IWhereAmIResponseLeaderboardMerchandiseGiveaway[];
}

export interface IWhereAmIResponseLeaderboardTier {
	PositionEnd: number;
	PositionStart: number;
	Tags: (IWhereAmIResponseTag & { Amount: number })[];
}

export type TWhereAmIResponseLeaderboardMerchandiseGiveawayProvider = 'telegram' | 'youtube' | 'twitch' | 'steam';

export interface IWhereAmIResponseLeaderboardMerchandiseGiveaway {
	When: {
		Links: {
			Provider: TWhereAmIResponseLeaderboardMerchandiseGiveawayProvider;
			Checked: boolean;
		}[];
		Subscriptions: {
			Provider: TWhereAmIResponseLeaderboardMerchandiseGiveawayProvider;
			ChannelID: string;
			ChannelLink: string;
			Checked: boolean;
		}[];
		Tags: {
			Tag: string;
			Checked: boolean;
		}[];
		PointsThreshold?: {
			LowerThreshold: number;
			UpperThreshold: number;
			IterAmount: number;
			Recurring: boolean;
			Checked: boolean;
		};
	}[];
	Items: {
		State: 'NOT_STARTED' | 'PENDING' | 'FULFILLED';
		Tag: IWhereAmIResponseTag & {
			ActiveSince: string | null;
			ActiveUntil: string | null;
		};
		FulfilledAt: string;
		Resource: string | null;
	}[];
}

export interface ILeaderboardListResponse {
	List: {
		ID: {
			ID: string;
			Platform: 'twitch';
		};
		Slug: string;
		Position: `${number}`;
		Score?: `${number}`;
		Requester?: true;
	}[];
	Total: number;
}

export type TCRMLocalizedValue = string | Record<TIntlLocale, string>;

export interface ICRMMedia {
	id: string;
	oid: string;
	ar: boolean;
	filename: string;
	mimeType: string;
	filesize: number;
	width: number;
	height: number;
	focalX: number;
	focalY: number;
	createdAt: string;
	updatedAt: string;
	url: string;
}

export interface ICRMLeaderboardActiveResponseDocGiftItem {
	id: string;
	amount: number;
	gift: {
		id: string;
		title: TCRMLocalizedValue;
		desription: TCRMLocalizedValue;
		image: ICRMMedia;
	};
}

export interface ICRMLeaderboardActiveResponseDocGift {
	id: string;
	items: ICRMLeaderboardActiveResponseDocGiftItem[];
	positionFrom: number;
	positionTo?: number;
}

export interface ICRMLeaderboardActiveResponseDocSchedule {
	id: string;
	date: string;
	items: ICRMLeaderboardActiveResponseDocScheduleItem[];
}

export interface ICRMLeaderboardActiveResponseDocScheduleItem {
	id: string;
	activity: string;
	period: string;
	description: TLexicalContent;
}

export interface ICRMLeaderboardActiveResponseDoc {
	id: string;
	oid: string;
	resource: string;
	title: TCRMLocalizedValue;
	description?: TCRMLocalizedValue;
	begin?: string;
	end?: string;
	logoImage?: ICRMMedia;
	backgroundImage?: ICRMMedia;
	gifts: ICRMLeaderboardActiveResponseDocGift[];
	schedule: ICRMLeaderboardActiveResponseDocSchedule[];
}

export interface ICRMLeaderboardActiveResponse {
	docs: ICRMLeaderboardActiveResponseDoc[];
	totalDocs: number;
}

export interface ICRMTextBlockResponse {
	id: string;
	oid: string;
	tbid: string;
	title: TCRMLocalizedValue;
	content: TLexicalContent;

	error?: string;
}
